import React from 'react';
import Social from './Social';
import FormContacto from './FormContacto';

export default function Contacto() {
  return (
    <section id="Contacto" className="Contact">
      <div className="container">
        <FormContacto />
        <Social />
      </div>
    </section>
  )
}
