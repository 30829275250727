import React from 'react';

export default function Curso(props) {
	var Data = props.data;
	return (
		<article className="Curso" key={Data.id}>
			<figure className="Certificado-wrapper">
				<picture >
					<img async className="Certificado" src={Data.Cert} alt='Certificado' />
				</picture>
			</figure>
			<div className="Curso-detalle">
				<h3 className="Curso-title">{Data.Name}</h3>
				<p><strong>Fecha:  </strong>  {Data.Date}</p>
				<p className="Curso-description">{Data.Description}</p>
				<a className="Curso-url" href={Data.Url} target="_blank" rel="noopener noreferrer">Ver el certificado</a>
			</div>
		</article>
	)
}
