import React, { Component } from 'react';
import Curso from './Curso';

const Cursos = require('./cursos.json');

export default class Conocimientos extends Component {
	constructor(props) {
		super(props);
			this.state = { Cursos: Cursos.Cursos };
	}
	render() {
		const { Cursos } = this.state;
		return (
			<div>
				<div className="container"><h2 className="Curso-list-title">Mis Conocimientos</h2></div>
				<section id="Conocimientos" className="Curso-list">
					<div className="container">
						{
							Cursos.map((Data) => {
								return (
									<Curso data={Data} />
								)
							})
						}
					</div>
				</section>
			</div>
		)
	}
}
