import React from 'react';
import Contacto from './Contacto';

export default function Footer() {
  return (
    <div>
      <Contacto />
      <footer id="Footer" className="footer">
        <div className="container">
          <div>
            <p>Curso de Desarrollo web Online 2018 <img async width="80px" src="./images/platzi.png" alt="Platzi Logo" /></p>
          </div>
          <div>
            <p>
              Designed with ❤ by <a href="https://twitter.com/thespianartist">@thespianartist</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
