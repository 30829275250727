import React from 'react';
import Proyecto from './Proyecto';

export default function Portafolio() {
  return (
    <section id="Portafolio" className="Portfolio">
      <div className="container">
        <h2>Portafolio (Proyectos Destacados)</h2>
        <Proyecto name="Platzi Video" relatedCourse="React/React Native" date="25/09/2018" link="http://www.platzi.com/native"
        description="PlatziVideo es el proyecto realizado durante el curso de React Native de Platzi
				  Durante este fue posible comprender y aplicar el funcionamiento de React y React Native.
				  Desde crear un vista-detalle, hasta patrones avanzados de diseño"
        imageSrc="./images/platzi-video-react-native" imgAlt="Proyecto del Curso de React"
          />
        <Proyecto name="Platzi Podcast" relatedCourse="Next.JS" date="15/07/2018" link="https://next.luisoteko.xyz"
          description="App de Podcasts integrada con la API de AudioBoom para aprender Next.JS" imageSrc="./images/Podcast" imgAlt="Proyecto del Curso de Next.JS"
        />
        <Proyecto name="Platzi Recetas" relatedCourse="PWA con React" date="15/07/2018" link="https://pwa.luisoteko.xyz"
          description="PWA que utiliza la API de MealDB para mostrar recetas con sus instrucciones." imageSrc="./images/Recetas" imgAlt="Proyecto del Curso de PWA con React.JS"

        />
      </div>
    </section>
  )
}
