import React from 'react';

export default function Hero() {
  return (
    <div>
      <section className="hero" id="hero">
        <div className="container">
          <h1 className="hero-title">Hola! Soy <strong>Luis Becerra</strong><br />Desarrollador <strong>Frontend</strong></h1>
          <picture>
            <source srcSet="./images/landing.webp" type="image/webp" className="hero-image" widht="500" height="300" />
            <source srcSet="./images/landing.jpeg" type="image/jpg" className="hero-image" widht="500" height="300" />
            <img src="./images/landing.jpeg" alt="Me" className="hero-image" widht="500" height="300" />
          </picture>
        </div>
      </section>
    </div>
  )
}
