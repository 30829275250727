import React from 'react';
import './App.css';
import Layout from './Layout';
import Portafolio from './Portafolio';
import Conocimientos from './Conocimientos';
import Hero from './Hero';

export default function App() {

    return (
      <Layout>
        <Hero/>
        <Portafolio/>
        <Conocimientos/>
      </Layout>
    )
}
