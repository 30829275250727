import React from 'react'

export default function Social() {
	return (
		<div className="social">
			<a href="https://twitter.com/luisoteko" className="social-link icon-twitter" aria-label="Twitter"> </a>
			<a href="https://facebook.com/luisoteko" className="social-link icon-facebook" aria-label="Facebook"> </a>
			<a href="https://github.com/luisoteko" className="social-link icon-github" aria-label="Github"> </a>
			<a href="https://instagram.com/luisoteko" className="social-link icon-instagram" aria-label="Instagram"> </a>
		</div>
	)
}
