import React from 'react'

export default function FormContacto() {
	return (
		<form action="https://docs.google.com/forms/d/e/1FAIpQLSe-N3cV9yUYe6dx2ImC8GLI1noYcDcuW5F0h64eICpMF7p5JA/formResponse" className="form-email">
			<label htmlFor="email">
				<h3><strong>Creamos algo juntos?</strong></h3>
				<input type="email" placeholder="Déjame tu email" id="email" name="entry.1045781291" required />
			</label>
			<button type="submit">Enviar</button>
		</form>
	)
}
