import React from 'react';

export default function Proyecto(props) {
	return (
		<article className="project">
			<div className="project-details">
				<h3 className="project-title">{props.name}</h3>
				<h6 className="project-course">{props.relatedCourse}</h6>
				<p className="project-date"><small><strong>Fecha:</strong> {props.date}</small></p>
				<p className="project-url"><small><strong>Puedes verlo en: </strong> <a className="link" href={props.link}>{props.name}</a> </small></p>
				<p className="project-description">{props.description}</p>
			</div>
			<picture className="project-image-wrapper">
				<source srcSet={props.imageSrc +".webp"} type="image/webp" />
				<source srcSet={props.imageSrc +".png"} type="image/png" />
				<img async className="project-image" width='500px' src={props.imageSrc +".png"} alt={props.imgAlt} />
			</picture>
		</article>
	)
}
