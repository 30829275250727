import React, { Component } from 'react'

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { isActive: false };
		this.activate = this.activate.bind(this);
	}
	activate() {
		this.setState({
			isActive: !this.state.isActive
		});
	}
	render() {
		return (
			<div>
				<i className={this.state.isActive ? "icon-close burger-button" : "icon-menu burger-button"} onClick={this.activate} />
				<header className="header">
					<div className="container">
						<figure className="logo">
							<picture>
								<source srcSet="././images/logo.webp" type="image/webp" />
								<source srcSet="././images/logo.png" type="image/png" />
								<img async src="././images/logo.png" height="50" alt="logo" />
							</picture>
							<p>Luisoteko</p>
						</figure>
						<nav className={this.state.isActive ? "menu is-active" : "menu"}>
							<ol>
								<li>
									<a className="link" href="#Portafolio">Portafolio</a>
								</li>
								<li>
									<a className="link" href="#Conocimientos">Conocimientos</a>
								</li>
								<li>
									<a className="link" href="#Contacto">Trabajemos Juntos</a>
								</li>
							</ol>
						</nav>
					</div>
				</header>
			</div>
		)
	}
}
